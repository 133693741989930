import React, { createRef, useEffect } from 'react';

export default function Home(): React.ReactElement {
    const videoRef = createRef<HTMLVideoElement>();
    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if (videoRef.current == null) {
            return;
        }

        if (vw < 600) {
            videoRef.current.src =
                'https://res.cloudinary.com/kenshikaifi/video/upload/c_scale,vc_h264,w_606/v1617546349/video_jlf74o.mp4';
        } else {
            videoRef.current.src =
                'https://res.cloudinary.com/kenshikaifi/video/upload/vc_h264/v1617546349/video_jlf74o.mp4';
        }
    }, [videoRef]);
    const onVideoEnded = () => {
        if (videoRef.current) {
            videoRef.current.load();
            videoRef.current.autoplay = false;
        }
    };
    return (
        <>
            <section className="container p-4">
                <h3 className="title is-size-4">Okinawan goju-ryu karate in Helsinki</h3>
                <p className="mt-3">
                    Kenshikai Karate-Do ry was founded in 2020 and is based in Helsinki. It&rsquo;s
                    purpose is to advance it&rsquo;s members as effective fighters as well human
                    beings in general.
                </p>
                <p className="mt-3">
                    Powerful techniques and physical capabilities are important, but they are only a
                    part of effective self defense. More important is attitude of the practitioner:
                    self confident and calm karateka is usually able to avoid many bad situations
                    without using violence at all.
                </p>
                <p className="mt-3">
                    Kenshikai Karate-Do is not just an excercise club: we train to become better
                    martial artists using traditional Okinawan karate as a framework.
                </p>
                <p className="mt-3">
                    If you are interested to joining us, use the form in Contact us-page and
                    we&rsquo;ll get in touch.
                </p>
            </section>
            <section className="container p-4">
                <figure>
                    <video
                        ref={videoRef}
                        muted
                        poster="https://res.cloudinary.com/kenshikaifi/video/upload/so_52.5/v1617546349/video_jlf74o.jpg"
                        preload="false"
                        controls
                        onEnded={onVideoEnded}
                    />
                </figure>
                <aside className="is-italic">Clips from our training sessions</aside>
            </section>
        </>
    );
}
